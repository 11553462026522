import { graphql } from 'gatsby';
import React from 'react';

import PageHandler from '@jpp/shared/PageHandler';

import { TPage } from '../../types';

const ROOT_CLASSNAME = 'Page';

const Page: React.FunctionComponent<TPage> = (props) => {
  if (props.pageContext.slug === 'test') {
    return null;
  }

  return (
    <PageHandler
      className={ROOT_CLASSNAME}
      {...props}
    />
  );
};

export const pageQuery = graphql`
  query Page($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      ...ContentfulPage
    }
  }
`;

export default Page;
